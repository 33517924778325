<template>
  <panel noButton="true" title="COMPANY SEARCH">
    <div class="row">
      <div class="col-md-6">
        <p><b>Name: </b>{{ company.business_name }}</p>
        <p><b>Registration Number: </b> {{ company.registration_number }}</p>
        <p><b>Phone Number: </b>{{ company.phone_number }}</p>
        <p><b>KRA PIN: </b>{{ company.kra_pin }}</p>
      </div>
      <div class="col-md-6">
        <p><b>Physical Address: </b>{{ company.physical_address }}</p>
        <p><b>Postal Address: </b>{{ company.postal_address }}</p>
        <p><b>Registration Date: </b> {{ company.registration_date }}</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <h4>Directors</h4>
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>ID Type</th>
              <th>ID Number</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(d, i) in company.directors"
                :key="i"
            >
              <td>{{ i + 1 }}</td>
              <td>{{ d.name }}</td>
              <td>{{ d.id_type }}</td>
              <td>{{ d.id_number }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>

export default {
  data() {
    return {
      company: {},
      error: '',
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.get(`/api/v1/companies/cached?crp=${this.$route.params.crp}`).then(response => {
        this.company = response.data;
      });
    },
  }
}
</script>